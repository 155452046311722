import React from "react";
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom'


// Load CSS
import './Components/css/bootstrap.min.css'
import './Components/css/style.css'

// Route
import Home from './Components/Home'

function App() {
  console.log("Created by MAD Coders 2024");
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element = {<Home/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
